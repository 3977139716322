// RouteGraph.styles.js

import { styled } from '@mui/system';
import { Box, Button, ButtonGroup } from '@mui/material';

// Стилизованный контейнер для кнопок
export const ButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(5), // Отступы между кнопками 5 
    marginBottom: theme.spacing(2), // Отступ снизу 2 
}));

// Группа кнопок даты
export const DateButtonGroup = styled(ButtonGroup)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2), // Отступы между кнопками 2
    marginBottom: theme.spacing(2), // Отступ снизу 2
    flexWrap: 'wrap', // Позволяет кнопкам переноситься на новую строку при необходимости
}));

// Стилизованная кнопка
export const StyledButton = styled(Button)(({ theme }) => ({
    flex: '1 1 auto', // Растягиваем кнопку на всю доступную ширину внутри родительского контейнера
    maxWidth: '200px', // Максимальная ширина кнопки, чтобы она не растягивалась слишком сильно
    margin: theme.spacing(1), // Внешний отступ вокруг кнопки, 8 пикселей по умолчанию (можно адаптировать под ваши нужды)
    whiteSpace: 'nowrap', // Запрещаем перенос текста внутри кнопки
    overflow: 'hidden', // Скрытие лишнего текста, который не помещается
    textOverflow: 'ellipsis', // Обрезаем текст, который не помещается
}));

